<template>
  <v-navigation-drawer
    app
    :clipped="true"
    :disable-resize-watcher="true"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-list dense>
      <v-list-item
        class="no-hover"
        :ripple="false"
        @click="$emit('click-show-profile')"
      >
        <v-list-item-avatar size="100">
          <v-img :src="userAvatarSrc" :key="userAvatarKey"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item
        class="no-hover mb-3"
        :ripple="false"
        @click="$emit('click-show-profile')"
      >
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ userName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-3"></v-divider>

    <div class="mt-6 px-1">
      <temeno-circle-button class="mr-2" title="Workspace-Mitglieder" @click="setShowUsers">$list</temeno-circle-button>
      <temeno-circle-button class="mr-2" title="Favoriten" @click="setShowFavorites">
        $favorite_green
      </temeno-circle-button>
      <temeno-circle-button title="Gruppen" @click="setShowGroups">
        $participants
      </temeno-circle-button>
    </div>

    <navigation-user-list v-show="showUsers" />
    <navigation-favorites-list
      v-show="showFavorites"
      @edit-group="onFavoritesListEditGroup"
    />
    <navigation-group-list
      v-show="showGroups"
      @edit-group="onGroupListEditGroup"
      @new-group="groupEditor.show = true"
    />

    <group-editor
      v-model="groupEditor.group"
      :show="groupEditor.show"
      @cancel="
        groupEditor.group = null;
        groupEditor.show = false;
      "
      @input="onGroupEditorInput"
    ></group-editor>

    <template v-slot:append>
      <div class="pa-2">
        <temeno-button block @click="showRegistration = true">
          <v-icon left>$create_contact</v-icon>
          Benutzer einladen
        </temeno-button>
      </div>

      <registration-dialog
        :show="showRegistration"
        @cancel="showRegistration = false"
        @submit="showRegistration = false"
      ></registration-dialog>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",
  props: ["value"],
  components: {
    GroupEditor: () => import("@/components/GroupEditor"),
    RegistrationDialog: () => import("@/components/RegistrationDialog"),
    NavigationFavoritesList: () => import("./NavigationFavoritesList"),
    NavigationGroupList: () => import("./NavigationGroupList"),
    NavigationUserList: () => import("./NavigationUserList"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
    TemenoButton: () => import("@/components/TemenoButton"),
  },
  data() {
    return {
      groupEditor: {
        group: null,
        show: false,
      },
      showFavorites: false,
      showGroups: false,
      showRegistration: false,
      showUsers: true,
    };
  },
  computed: {
    userAvatarKey() {
      return this.$store.state.user.avatarLastChanged;
    },
    userAvatarSrc() {
      return this.$store.state.user.avatar.url + "?t=" + this.userAvatarKey;
    },
    userName() {
      return this.$store.state.user.showName;
    },
  },
  methods: {
    onGroupEditorInput(group) {
      this.$store.dispatch("groups/save", group).then(() => {
        this.groupEditor.group = null;
        this.groupEditor.show = false;
      });
    },
    onFavoritesListEditGroup(group) {
      this.groupEditor.group = group;
      this.groupEditor.show = true;
    },
    onGroupListEditGroup(group) {
      this.groupEditor.group = group;
      this.groupEditor.show = true;
    },
    setShowFavorites() {
      this.showFavorites = true;
      this.showGroups = false;
      this.showUsers = false;
    },
    setShowGroups() {
      this.showFavorites = false;
      this.showGroups = true;
      this.showUsers = false;
    },
    setShowUsers() {
      this.showFavorites = false;
      this.showGroups = false;
      this.showUsers = true;
    },
  },
};
</script>
